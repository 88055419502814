.el-scrollbar[data-v-692f7b48] {
  height: 100%;
}
.box .el-scrollbar__wrap[data-v-692f7b48] {
  overflow: scroll;
}
[data-v-692f7b48] .UserDeptTree .el-tree {
  height: 60vh !important;
  max-height: 60vh !important;
}
[data-v-692f7b48] .UserDeptCrud .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
[data-v-692f7b48] .el-dialog {
  margin-bottom: 0px !important;
}